<template>
  <div>
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Collection Account</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Account Name"
              invalid-feedback="Account name is required."
            >
              <b-form-input
                ref="accname"
                v-model="accountObj.account"
                placeholder="Enter collection account name"
                @focusout="checkAccount()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveAcc()"
              :disabled="savingacc"
            >
              <b-spinner v-if="savingacc" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          :tbody-tr-class="rowClass"
          show-empty
          :items="accTypes"
          :fields="accFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editAcc(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteAcc(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-search"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Search</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12" class="mb-1">
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="loadSearch()"
                style="cursor: pointer"
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="searchQuery"
                @keyup.enter="loadSearch()"
                placeholder="Search by name or gr#"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>

      <b-table
        class=""
        show-empty
        :busy="studentLoading"
        ref="studentSelect"
        :items="searchData"
        :fields="fields"
        responsive
        hover
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                :src="data.item.picture"
                variant="light-primary"
              />
            </template>

            <b-badge variant="light-primary">
              {{ data.item.name }}
            </b-badge>

            <span
              class="font-weight-bold d-block text-nowrap"
              style="margin: 4px 0px"
            >
              <b-badge variant="light-primary">
                F/N: {{ data.item.father_name }}
              </b-badge>
            </span>

            <small class="text-muted">
              <b-badge variant="light-primary">
                GR - {{ data.item.grNo }}
              </b-badge>
              <b-badge variant="light-primary" style="margin-inline: 10px">
                {{ data.item.meta_class }}
              </b-badge>
            </small>
          </b-media>
        </template>
      </b-table>
      <sidebar-content />
    </b-sidebar>

    <b-row>
      <b-col xl="8" lg="8" md="12">
        <b-row>
          <b-col>
            <b-form-group label="Search">
              <b-form-input
                v-model.trim="productQuery"
                placeholder="Enter product name to search"
                @keyup.enter="LoadProduct()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="12" v-if="dataLoading" class="align-items-center">
            <b-spinner
              style="width: 5rem; height: 5rem"
              type="grow"
              variant="primary"
              label="loading"
              class="mt-3 mb-3"
            ></b-spinner>
          </b-col>
          <b-col
            md="12"
            v-else-if="products.length == 0"
            class="align-items-center"
          >
            <h4 class="mt-3 mb-3">No Records Found</h4>
          </b-col>
          <b-col
            v-else
            xl="4"
            lg="6"
            md="4"
            v-for="(item, ind) in products"
            :key="item.id"
          >
            <b-card no-body>
              <div class="item-img text-center">
                <b-img
                  :alt="item.name"
                  class="card-img-top"
                  :src="item.image"
                  height="200"
                />
              </div>

              <b-card-body>
                <h5 class="font-weight-bolder">{{ item.name }}</h5>
                <div
                  class="d-flex align-items-center justify-content-between flex-wrap"
                >
                  <!-- <p class="mb-0">Quantity: {{ item.quantity }}</p> -->
                  <p class="mb-0">
                    <span
                      :class="
                        item.quantity == 0 ? 'text-danger' : 'text-success'
                      "
                      >{{ item.quantity == 0 ? "Out Stock" : "In stock" }}
                    </span>
                    ({{ item.quantity }})
                  </p>
                  <h6 class="mb-0">Rs.{{ item.sellingPrice }}</h6>
                </div>
                <!-- <p class="mb-0">
                  <span
                    :class="item.quantity == 0 ? 'text-danger' : 'text-success'"
                    >{{ item.quantity == 0 ? "Out Stock" : "In stock" }}
                  </span>
                  ({{ item.quantity }})
                </p> -->
                <b-button
                  variant="primary"
                  class="btn-icon mt-2"
                  @click="addItem(item)"
                  block
                >
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span>Add to Cart</span>
                </b-button>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="4" lg="4" md="12">
        <b-row>
          <b-col>
            <b-form-group label="Enter SKU or scan with barcode">
              <b-form-input
                v-model.trim="skuQuery"
                placeholder="SKU number"
                @keyup.enter="loadSku()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card>
          <!-- <div class="d-flex align-items-start justify-content-end">
            <feather-icon
              icon="SearchIcon"
              @click="openSearch()"
              size="22"
              class="cursor-pointer"
              v-b-tooltip.hover.left
              title="Search Student"
            />
          </div> -->

          <div class="d-flex align-items-start justify-content-center mb-2">
            <div v-if="currentStudent" class="text-center">
              <b-avatar
                size="78"
                :src="currentStudent.picture"
                variant="light-primary"
                @click="openSearch()"
                button
                class="cursor-pointer"
                v-b-tooltip.hover.top
                title="Click to search student"
              />
              <h5 class="text-center mt-1">
                <span class="label">{{ currentStudent.name }}</span>
              </h5>
              <h5 class="text-center">
                <span class="label">{{ currentStudent.meta_class }}</span>
              </h5>
              <!-- <b-media vertical-align="center" v-if="currentStudent">
                <template #aside>
                  <b-avatar
                    size="48"
                    :src="currentStudent.picture"
                    variant="light-primary"
                  />
                </template>

                <b-badge variant="light-primary">
                  {{ currentStudent.name }}
                </b-badge>

                <span
                  class="font-weight-bold d-block text-nowrap"
                  style="margin: 4px 0px"
                >
                  <b-badge variant="light-primary">
                    F/N: {{ currentStudent.father_name }}
                  </b-badge>
                </span>

                <small class="text-muted">
                  <b-badge variant="light-primary">
                    GR - {{ currentStudent.grNo }}
                  </b-badge>
                  <b-badge variant="light-primary" style="margin-inline: 10px">
                    {{ currentStudent.meta_class }}
                  </b-badge>
                </small>
              </b-media> -->
            </div>
            <div v-else class="text-center">
              <b-avatar
                size="78"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                variant="light-primary"
                @click="openSearch()"
                button
                class="cursor-pointer"
                v-b-tooltip.hover.top
                title="Click to search student"
              />
            </div>
          </div>
          <div class="">
            <h6 class="price-title mb-1">Price Details</h6>
            <hr />
            <ul class="list-unstyled set-height">
              <li class="mb-1" v-if="cartItems.length == 0">
                <div class="">No Items in cart</div>
              </li>
              <li
                v-else
                class="d-flex mb-1"
                v-for="(item, ind) in cartItems"
                :key="item.id"
                style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1)"
              >
                <b-img
                  :alt="item.name"
                  :src="item.image"
                  width="100"
                  style="border-radius: 7px"
                />
                <div class="p-1 w-100" style="position: relative">
                  <div class="font-weight-bolder">{{ item.name }}</div>
                  <div class="font-weight-bold">Rs.{{ item.price }}</div>
                  <b-form-spinbutton
                    v-model="item.qty"
                    size="sm"
                    class="mr-75 mt-50"
                    inline
                    :max="item.quantity"
                    @input="getPrice(item, ind)"
                  />
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-danger mr-50"
                    size="22"
                    @click="removeItem(ind)"
                    style="position: absolute; top: 2px; right: 0"
                  />
                </div>
              </li>
              <!-- <li
                class="d-flex justify-content-between mb-1"
                v-for="(item, ind) in cartItems"
                :key="item.id"
              >
                <div class="">
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-danger mr-50"
                    size="18"
                    @click="removeItem(ind)"
                  />
                  <b-form-spinbutton
                    v-model="item.qty"
                    size="sm"
                    class="mr-75"
                    inline
                    :max="item.quantity"
                    @input="getPrice(item, ind)"
                  />
                  <span class="">{{ item.name }}</span>
                </div>
                <div class="">Rs.{{ item.price }}</div>
              </li>
              -->

              <!-- <li class="d-flex justify-content-between mb-1">
                <div class="">Bag Discount</div>
                <div class="text-success">-25$</div>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="">Estimated Tax</div>
                <div class="">$1.3</div>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="">EMI Eligibility</div>
                <a href="javascript:void(0)" class="text-primary">Details</a>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="">Delivery Charges</div>
                <div class="text-success">Free</div>
              </li> -->
            </ul>
          </div>
          <div>
            <hr />
            <ul class="list-unstyled">
              <!-- <li class="d-flex justify-content-between mb-1">
                <div class="font-weight-bolder">Total</div>
                <div class="font-weight-bolder">Rs.{{ totalPrice }}</div>
              </li> -->
              <b-form-group label="Total">
                <b-form-input
                  disabled
                  v-model="totalPrice"
                  class="form-control"
                  placeholder="Total"
                />
              </b-form-group>
              <b-form-group
                label="Receiving amount"
                invalid-feedback="Invalid amount entered"
              >
                <b-form-input
                  ref="paid"
                  type="number"
                  v-model="receiveObj.amount"
                  @input="getBalance()"
                  class="form-control"
                  placeholder="Receiving amount"
                />
              </b-form-group>
              <b-form-group
                label="Balance"
                invalid-feedback="Balance is required."
              >
                <b-form-input
                  disabled
                  v-model="balance"
                  class="form-control"
                  placeholder="Balance"
                />
              </b-form-group>
              <b-form-group
                label="Receiving Date"
                invalid-feedback="receiving date is required."
                ref="rcvDate"
              >
                <flat-pickr
                  ref="rcvDate"
                  :config="config"
                  v-model="receiveObj.rcvDate"
                  class="form-control"
                  placeholder="Select Date."
                  @on-change="checkDate()"
                />
              </b-form-group>
            </ul>
            <b-button variant="primary" block @click="confirm()">
              Confirm Payment
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      visibility: false,
      searchQuery: "",
      studentLoading: false,
      searchData: [],
      currentStudent: null,
      fields: ["student"],
      cartItems: [],
      skuQuery: "",
      productQuery: "",
      products: [
        {
          id: 1,
          name: "Bag",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 120,
          purchasePrice: 4500,
          sellingPrice: 110,
          image: require("@/assets/images/myimages/bag.jpeg"),
        },
        {
          id: 3,
          name: "Crayons",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 10,
          purchasePrice: 100,
          sellingPrice: 15,
          image: require("@/assets/images/myimages/crayons.jpeg"),
        },
        {
          id: 4,
          name: "Color Paper",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 100,
          purchasePrice: 4000,
          sellingPrice: 12,
          image: require("@/assets/images/myimages/papers.jpg"),
        },
        {
          id: 5,
          name: "Pens Set",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 50,
          purchasePrice: 1500,
          sellingPrice: 25,
          image: require("@/assets/images/myimages/pens.jpeg"),
        },
        {
          id: 6,
          name: "Notebook Pack",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 80,
          purchasePrice: 3000,
          sellingPrice: 20,
          image: require("@/assets/images/myimages/notebookpack.jpeg"),
        },
        {
          id: 7,
          name: "Sticky Notes Bundle",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 120,
          purchasePrice: 2000,
          sellingPrice: 15,
          image: require("@/assets/images/myimages/sticky.jpeg"),
        },
        {
          id: 8,
          name: "Journal",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 60,
          purchasePrice: 2500,
          sellingPrice: 18,
          image: require("@/assets/images/myimages/journal.jpeg"),
        },
        {
          id: 9,
          name: "Eraser Set",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 200,
          purchasePrice: 1000,
          sellingPrice: 8,
          image: require("@/assets/images/myimages/eraser.jpeg"),
        },
        {
          id: 10,
          name: "School Sweaters",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 50,
          purchasePrice: 8000,
          sellingPrice: 200,
          image: require("@/assets/images/myimages/sweater.png"),
        },
        {
          id: 12,
          name: "Laptop Cooling Pad",
          warehouseID: 1,
          categoryID: 3,
          supplierID: 3,
          quantity: 50,
          purchasePrice: 11000,
          sellingPrice: 250,
          image: require("@/assets/images/myimages/cooling.jpg"),
        },
        {
          id: 13,
          name: "Wireless Microphones",
          warehouseID: 1,
          categoryID: 3,
          supplierID: 3,
          quantity: 0,
          purchasePrice: 2500,
          sellingPrice: 180,
          image: require("@/assets/images/myimages/microphone.jpg"),
        },
        {
          id: 2,
          name: "Shoes",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 60,
          purchasePrice: 2000,
          sellingPrice: 150,
          image: require("@/assets/images/myimages/shoes.jpeg"),
        },
      ],
      receiveObj: {
        rcvDate: new Date().toJSON(),
        amount: 0,
      },
      balance: 0,
      dataLoading: false,
      accountObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
        accountID: 0,
      },
      accIcon: "",
      visibility2: false,
    };
  },
  computed: {
    totalPrice() {
      let total = this.cartItems.reduce((sum, el) => sum + el.price, 0);
      return total;
    },
  },
  created() {
    this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "posStock/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.products = await this.get(obj);
      this.dataLoading = false;
    },

    async LoadProduct() {
      if (this.productQuery == "") {
        this.LoadData();
      } else {
        this.dataLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "posStock/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.productQuery,
          token: this.$store.state.userData.token,
        };
        this.products = await this.get(obj);
        this.dataLoading = false;
      }
    },
    async loadSku() {
      if (this.skuQuery !== "") {
        var obj = {
          url:
            this.$store.state.domain +
            "posStock/SearchSku?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&sku=" +
            this.skuQuery,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        console.log(res);
        if (res) {
          this.addItem(res);
        } else {
          this.$bvToast.toast("No product found", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-right",
          });
        }
      }
    },

    getBalance() {
      var elem = this.$refs["paid"];
      if (this.receiveObj.amount > this.totalPrice) {
        elem.state = false;
      } else {
        this.balance = this.totalPrice - this.receiveObj.amount;
        elem.state = undefined;
      }
    },

    getPrice(item, ind) {
      item.price = item.sellingPrice * item.qty;
      this.cartItems.splice(ind, 1, item);
      this.receiveObj.amount = this.totalPrice;
      this.getBalance();
    },

    addItem(item) {
      if (item.quantity == 0) {
        this.$bvToast.toast("This Product is not in stock!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      } else {
        let obj = this.cartItems.find((el) => el.id == item.id);
        if (!obj) {
          item.qty = 1;
          item.price = item.sellingPrice;
          this.cartItems.push(item);
          this.receiveObj.amount = this.totalPrice;
        }
      }
      this.getBalance();
    },
    removeItem(ind) {
      this.cartItems.splice(ind, 1);
      this.receiveObj.amount = this.totalPrice;
      this.getBalance();
    },

    confirm() {
      // console.log(this.cartItems);
      if (this.cartItems.length == 0) {
        this.$bvToast.toast("Please add product to cart", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      } else if (!this.currentStudent) {
        this.$bvToast.toast("Please select the student", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      } else {
        console.log(this.cartItems);
      }
    },

    openSearch() {
      this.visibility = true;
    },
    async loadSearch() {
      if (this.searchQuery.trim() !== "") {
        this.studentLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "Students/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery +
            "&type=&status=present",
          token: this.$store.state.userData.token,
        });
        this.searchData = myitem;
        // console.log("stud", this.searchData);
        this.studentLoading = false;
      }
    },
    onRowSelected(row) {
      console.log(row[0]);
      if (row[0]) {
        this.currentStudent = row[0];
        this.visibility = false;
      }
      // console.log(this.currentStudent);
    },

    // async loadAccounts() {
    //   var obj2 = {
    //     url:
    //       this.$store.state.domain +
    //       "CollectionAccounts?db=" +
    //       this.$store.state.userData.db +
    //       "&cID=" +
    //       this.$store.state.userData.cId,
    //     token: this.$store.state.userData.token,
    //   };
    //   let result = await this.get(obj2);
    //   // console.log("acc", result);
    //   if (result !== "NotFound") this.accTypes = result;
    //   else this.accTypes = [];
    //   // if exists
    //   if (this.accTypes.find((el) => el.id == this.$store.state.accountType)) {
    //     this.accountType = this.$store.state.accountType;
    //   }
    //   // not exists then first record
    //   else if (this.accTypes.length > 0) {
    //     this.accountType = this.accTypes[0].id;
    //     this.$store.commit("setAccountType", this.accountType);
    //   }
    //   // no records of accounts then set to 0
    //   else {
    //     this.$store.commit("setAccountType", 0);
    //     this.accountType = this.$store.state.accountType;
    //   }
    // },
    openAccount() {
      this.accIcon = "PlusIcon";
      this.accountbar = true;
      this.accountObj = {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
        accountID: 0,
      };
      var elem = this.$refs["accname"];
      elem.state = undefined;
    },
    checkAccount() {
      var elem = this.$refs["accname"];
      if (this.accountObj.account == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveAcc() {
      if (this.checkAccount() == true) {
        this.savingacc = true;
        if (this.accountObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "CollectionAccounts?db=" +
              this.$store.state.userData.db,
            body: this.accountObj,
            message: "Account added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "CollectionAccounts/" +
              this.accountObj.id +
              "?db=" +
              this.$store.state.userData.db,

            message: "Account updated successfully.",
            context: this,
            body: this.accountObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.loadAccounts();
          this.accountObj = {
            id: 0,
            campusID: this.$store.state.userData.cId,
            account: "",
            status: "active",
            accountID: 0,
          };
          var elem = this.$refs["accname"];
          elem.state = undefined;
        }
        this.savingacc = false;
      }
    },
    async editAcc(acc) {
      this.accountObj = { ...acc };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["accname"];
      elem.state = undefined;
      // console.log(this.accountObj);
    },
    async deleteAcc(acc) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "CollectionAccounts/" +
            acc.id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Account deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadAccounts();
      }
    },
  },
};
</script>
<style>
.set-height {
  min-height: 250px;
  /* position: relative;  */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
